<template>
  <div class="md-category-page">
    <div class="md-cate-box">
      <div class="search-item">
        <el-input
          class="w-280"
          v-model="params.searchValue"
          placeholder="考研有什么条件"
          prefix-icon="el-icon-search"
          :clearable="true"
        >
          <el-button slot="append" class="search" @click="getVideoListByCategoryId(1)"
            >搜索</el-button
          >
        </el-input>
      </div>
      <div class="md-cate-panel">
        <div class="type-box">
          <div class="type-list">
            <b>方向：</b>
            <span
              :class="[
                'type-item',
                +pIndex === item.rid ? 'type-item-active' : '',
              ]"
              v-for="(item, index) in categoryAryList"
              :key="index"
              @click="handleCategory(item)"
              >{{ item.name }}</span
            >
          </div>
          <div class="type-list">
            <b>分类：</b>
            <span
              :class="[
                'type-item',
                +cIndex === c.rid ? 'type-item-active' : '',
              ]"
              v-for="(c, i) in categoryItemInfo.categoryList"
              :key="i"
              @click="handleCategoryItem(c)"
              >{{ c.name }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="md-class-con">
      <div class="md-class-container">
        <div class="all">
          <img
            style="width: 32px; height: 32px"
            src="../assets/img/course-title2.png"
            alt=""
          />
          <div>全部课程</div>
        </div>
        <ul class="md-class-list" v-if="videos.length">
          <li v-for="(item, index) in videos" :key="index">
            <MdItemClassComponent :classInfo="item" />
          </li>
        </ul>
        <p v-else class="no-data">暂无数据</p>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="+params.pageNum"
            :page-size="params.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCategoryList, getVideoListByCategoryId } from "@/axios/course";
import MdItemClassComponent from "../components/class/mdItem/index.vue";
export default {
  name: "MdPagesCategoryComponent",
  components: {
    MdItemClassComponent,
  },
  data() {
    return {
      params: {
        categoryId: "",
        searchValue: "",
        pageNum: 1,
        pageSize: 20,
      },
      categoryAryList: [],
      categoryItemInfo: {},
      pIndex: null,
      cIndex: -1,
      videos: [],
      total: 0,
    };
  },
  created() {
    if (sessionStorage.getItem("deptId")) {
      console.log(sessionStorage.getItem("deptId"), "---deptId");
      this.params.deptId = sessionStorage.getItem("deptId");
    }

    this.getCategoryList(1);
  },
  methods: {
    disposeData() {
      this.categoryAryList.forEach((item) => {
        item.categoryList.unshift({
          rid: -1,
          name: "不限",
        });
      });
      this.params.searchValue = this.$route.query.searchValue
        ? this.$route.query.searchValue
        : "";
      this.pIndex = this.$route.query.pId
        ? this.$route.query.pId
        : this.categoryAryList[0].rid;
      // 存在父级id就找到对应的子级，然后高亮子级
      if (this.$route.query.pId) {
        const cur = this.categoryAryList.filter((item) => {
          return item.rid === +this.$route.query.pId;
        });
        this.categoryItemInfo = cur[0];
        this.params.categoryId = this.$route.query.cId;
      } else {
        this.categoryItemInfo = this.categoryAryList[0];
        this.params.categoryId = this.categoryItemInfo.rid;
      }
      console.log(this.categoryItemInfo, "print");
      this.cIndex = this.$route.query.cId ? this.$route.query.cId : -1;
      this.getVideoListByCategoryId(1);
    },
    async getCategoryList() {
      const res = await getCategoryList(1);
      if (res.code === 200) {
        this.categoryAryList = res.data;
        this.disposeData();
      }
    },
    handleCategory(item) {
      this.categoryItemInfo = item;
      this.pIndex = item.rid;
      this.params.categoryId = item.rid;
      this.cIndex = -1;
      this.getVideoListByCategoryId(1);
    },
    // 根据分类id获取课程详情
    async getVideoListByCategoryId(pNo) {
      this.params.pageNum = pNo;
      const res = await getVideoListByCategoryId(this.params);
      if (res.code === 200) {
        this.videos = res.rows;
        this.total = res.total || 0;
      }
    },
    handleCategoryItem(item) {
      this.cIndex = item.rid;
      if (this.cIndex === -1) {
        this.params.categoryId = this.categoryItemInfo.rid;
      } else {
        this.params.categoryId = item.rid;
      }
      this.getVideoListByCategoryId(1);
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getVideoListByCategoryId(val);
    },
  },
};
</script>
<style lang="less" scoped>
.md-category-page {
  width: 100%;

  .all {
    display: flex;
    align-items: center;
    padding-bottom: 26px;

    > :nth-child(2) {
      margin-left: 8px;
      font-size: 24px;
    }
  }

  .md-cate-box {
    width: 100%;
    background: #ffffff;
    padding: 36px 0px;
    height: 300px;
    box-sizing: border-box;
    background-image: url("../assets/img/cour.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .md-cate-panel {
      width: 1152px;
      margin: 0 auto;
      position: absolute;
      box-sizing: border-box;
      background-color: #fff;
      height: 192px;
      border-radius: 12px;
      padding: 40px 20px;
      top: 156px; /* 顶部距离父元素顶部 50% */
      left: 50%; /* 左侧距离父元素左侧 50% */
      transform: translate(
        -50%,
        0
      ); /* 通过转换移动回自身宽高的 50%，实现居中 */
      .class-search {
        width: 98%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          height: 46px;
        }

        .search-box {
          /deep/ .search-input {
            .el-input__inner {
              border: none;
              width: 240px;
              box-sizing: border-box;
              background: #fff;
              border-radius: 18px;
              border: 1px solid @md-primary-color;
              height: 36px;
            }

            .el-input__suffix {
              i {
                margin-top: 10px;
                margin-right: 3px;
                font-size: 16px;
                font-weight: bold;
                color: @md-primary-color;
                cursor: pointer;

                &:hover {
                  color: @md-primary-color;
                }
              }
            }
          }
        }
      }

      .type-box {
        .type-list {
          padding: 10px;
          margin-bottom: 25px;

          b {
            font-size: 14px;
          }

          .type-item {
            color: #1c1f21;
            font-size: 14px;
            line-height: 16px;
            padding: 8px 12px;
            border-radius: 6px;
            margin: 0 12px 12px 0;
            cursor: pointer;
          }

          .type-item-active {
            background: @md-light-primary-color;
            color: @md-primary-color;
          }
        }
      }
    }
  }

  .md-class-con {
    width: 100%;
    background: #f4f5f9;
    max-width: 1920px;
    margin: 0 auto;

    .md-class-container {
      width: 1200px;
      margin: 40px auto;
      padding: 50px 0;

      .md-class-list {
        display: flex;
        flex-wrap: wrap;

        li {
          &:nth-child(4n) {
            .md-item-class-component {
              margin-right: 0;
            }
          }
        }
      }

      .pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}

.search-item {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  width: 671px;
  top: 60px; /* 顶部距离父元素顶部 50% */
  left: 50%; /* 左侧距离父元素左侧 50% */
  transform: translate(-50%, 0); /* 通过转换移动回自身宽高的 50%，实现居中 */
  .search-label {
    font-size: 14px;
    color: #333;
  }
}
/deep/ .el-pager li.active {
  color: @md-primary-color;
}

/deep/ .el-pager li:hover {
  color: @md-primary-color;
}

/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}
::v-deep {
  .el-input__inner {
    border-color: #ff882e;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: @md-primary-color;
  }
  .el-input-group__append {
    background-color: #ff882e;
    color: #fff;
    border-color: #ff882e;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border: 1px solid #cccccc;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
    background-color: #fff;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: @md-primary-color;
  }
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    height: 36px;
    line-height: 36px;
  }
}
</style>
